<template>
<div class="container">
  
  <SpacerRow />
  <CheckInList :start="start" />
  
</div> <!-- container -->
</template>

<script>
import { mapGetters } from 'vuex'

import ConstUtils    from '@/utils/ConstUtils.js'
import CheckInList from './CheckInList.vue'
import Catelog       from "@/domain/session/Catalog.js";
import SpacerRow     from '@/components/row/SpacerRow.vue';

export default {
  name: 'portal-inspections',
  components: {
    CheckInList,
    SpacerRow,
  },
  props: {
  },
  data() {
    return {
      start: false,
      C: new Catelog(),
    }
  },
  computed: {
    ...mapGetters([
                   'auth_connected',
                   'domain',
                   ]),
  },
  watch: {
  },
  mounted: function () {
    if (!this.auth_connected) {
      this.$router.replace({
        name: this.$route.query.redirectTo || ConstUtils.ROUTES.SIGNIN,
      })
    } else {
      this.start = true;
    }
    
  },
  methods: {
  },
}
</script>